import React from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import AboutUs from '~containers/about-us';

const AboutUsPage = () => {
  return (
    <Layout>
      <Seo title='About Us' />
      <AboutUs />
    </Layout>
  );
};

export default AboutUsPage;
