import React from 'react';

const AboutUs = () => {
  return (
    <div className='about-us'>
      <p>
        RENTZY = Rent + Easy - Thuê dễ dàng, mô hình “Tủ đồ trên mây - Cloud Wardrobe” đầu tiên tại Việt Nam giúp tối ưu
        vòng đời của sản phẩm thời trang nhiều nhất có thể, thay đổi thói quen người tiêu dùng từ thời trang nhanh sang
        thời trang có giá trị bền vững, sử dụng dịch vụ thuê để trải nghiệm thay vì sở hữu, và cuối cùng khách hàng hoàn
        toàn có thể mua lại các sản phẩm đã qua sử dụng với giá ưu đãi hơn so với giá hãng bất cứ khi nào yêu thích nếu
        sử dụng nó thường xuyên và lâu dài.
      </p>
      <p>
        Rentzy mong muốn tạo nên một phong cách sống mới - hiện đại, thông minh và sành điệu thoả mãn mong ước của phái
        đẹp.
      </p>
    </div>
  );
};

export default AboutUs;
